<script setup>
import StoreLayout from '@/Layouts/StoreLayout.vue';
import Row from '@/Pages/Product/Partials/Row.vue';
import {Head, Link} from '@inertiajs/vue3';
import Container from "@/Components/Container.vue";
import PrimaryLink from "@/Components/PrimaryLink.vue";

const props = defineProps({
    featuredTitle: String,
    featuredProducts: Array,
    collections: Array,
    sale: Array,
    highlight: Object,
});

const currentHour = new Date().getHours();
const testimonials = [
    {
        id: 1,
        quote:
            'My fabrics arrived today. They are wonderful! I am more than satisfied. Thank you for the service that your company provides.',
        attribution: 'Linda from Jenison, MI',
    },
    {
        id: 2,
        quote:
            'I just wanted to thank you for my order. It always arrives promptly and accurately. I can\'t wait to start using my new fabric "stash." Thank you for keeping your prices so reasonable.',
        attribution: 'Jennifer from Lake Nebagamon, WI',
    },
    {
        id: 3,
        quote:
            'I love your website! It\'s as great as your store. I have been there twice in the last seven years, once on my own, once with a bus tour. I would like a third visit. :)',
        attribution: 'Diane from Houston, TX',
    },
    {
        id: 4,
        quote:
            'This was my first time shopping at your website. I just wanted to take a moment to let you know that I am very happy with my order and I plan on shopping with you again soon!',
        attribution: 'Cindy from Maysville, GA',
    },
    {
        id: 5,
        quote:
            'I just received my first shipment of fabric. The fabric is first-rate quality, and I was delighted with the handwritten note on my order form. It is nice to see that customer service is alive and well with a personal touch.',
        attribution: 'Kathy from Bedford, TX',
    },
    {
        id: 6,
        quote:
            'The package arrived today and the fabric is just as I had hoped. Thank you so much. I told a friend about your shop and she informed me today that she placed an order online with you, too!',
        attribution: 'Susan from Largo, FL',
    },
    {
        id: 7,
        quote:
            'My fabric arrived today! I had looked at all the quilt shops in my area and everyone was out of it. The internet is great - and so are you. Thank you for the prompt service.',
        attribution: 'Carol from Olympia, WA',
    },
    {
        id: 8,
        quote:
            'It\'s my first order from you and I could not believe how quickly my fabrics arrived here in Australia. In just over a week from ordering, I had the fabrics in my hand, and this was over the holidays. Well done and thank yo.,',
        attribution: 'Christine from Burrum Heads, Australia',
    },
    {
        id: 9,
        quote:
            'Thank you so much. There isn\'t even ONE quilt store here in Puerto Rico, so your online fabric store is a real blessing. Thank you for having such nice pictures; it made it easy to plan my next quilt.',
        attribution: 'Kelly from Anasco, Puerto Rico',
    },
    {
        id: 10,
        quote:
            'This is my first order to you. I’ve never seen a fabric website with such nice organization and so many great fabrics. I love your ways of grouping by color, style, etc. There will be more orders.',
        attribution: 'Harriet from Seattle, WA',
    },
].sort((it) => currentHour - it.id).slice(0, 3);
</script>

<template>
    <Head title="Home"/>
    <StoreLayout>
        <main>
            <!-- Hero -->
            <div class="flex flex-col border-b border-gray-200 lg:border-0">
                <div class="relative">
                    <div aria-hidden="true" class="absolute hidden h-full w-1/2 bg-gray-100 lg:block"/>
                    <div class="relative bg-gray-100 lg:bg-transparent">
                        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8">
                            <div class="mx-auto max-w-2xl py-24 lg:max-w-none">
                                <div class="lg:pr-16">
                                    <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl">
                                        New site,<br/>same local store</h1>
                                    <p class="mt-4 text-xl text-gray-600">The Old Country Store in Lancaster County,
                                        Pennsylvania is known for high-quality quilting fabric, top-notch customer
                                        service, and great prices!<br/><br/>Old Country Store Fabrics allows you to shop
                                        from the
                                        convenience of home, and receive these same benefits.</p>
                                    <div class="mt-6">
                                        <Link :href="route('products.browse', 'fabric')"
                                              class="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 font-medium text-white hover:bg-indigo-700">
                                            Shop Fabric
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-48 w-full sm:h-64 lg:absolute lg:right-0 lg:top-0 lg:h-full lg:w-1/2">
                        <img src="/img/home.jpg" alt="" class="h-full w-full object-cover object-center"/>
                    </div>
                </div>
            </div>

            <Container>
                <Row class="mb-8" :title="featuredTitle" :products="featuredProducts"/>
            </Container>

            <!-- Collections -->
            <section aria-labelledby="collections-heading" class="bg-gray-50">
                <div class="mx-auto max-w-7xl py-16 lg:py-32 px-4 sm:px-6 lg:px-8">
                    <div class="flex flex-col gap-16">
                        <div class="">
                            <h2 id="collections-heading" class="text-2xl font-bold text-gray-900">Trending
                                Collections</h2>

                            <div class="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                                <div v-for="collection in props.collections" :key="collection.name"
                                     class="group relative">
                                    <div
                                        class="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                                        <img :src="collection.imageSrc" :alt="collection.imageAlt"
                                             class="h-full w-full object-cover object-center"/>
                                    </div>
                                    <h3 class="mt-6 text-sm text-gray-500">
                                        <Link :href="collection.url">
                                            <span class="absolute inset-0"/>
                                            {{ collection.name }}
                                        </Link>
                                    </h3>
                                    <p class="text-base font-semibold text-gray-900">{{ collection.description }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="highlight" class="lg:grid lg:grid-cols-2 lg:gap-x-8">
                            <div class="">
                                <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                    {{ highlight.name }}</h1>
                                <p class="mt-6 text-lg leading-8 text-gray-600">{{ highlight.description }}</p>
                                <div class="mt-10 flex items-center gap-x-6">
                                    <PrimaryLink :href="highlight.href">
                                        {{ highlight.action ?? 'Shop now' }}
                                    </PrimaryLink>
                                </div>
                            </div>
                            <div class="">
                                <img :src="highlight.imageSrc" alt="" class="w-full object-cover xl:rounded-xl"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Sale and testimonials -->
            <!-- Testimonials -->
            <section aria-labelledby="testimonial-heading"
                     class="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8 lg:py-32">
                <div class="mx-auto max-w-2xl lg:max-w-none">
                    <h2 id="testimonial-heading" class="text-2xl font-bold tracking-tight text-gray-900">What are
                        people saying?</h2>

                    <div class="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
                        <blockquote v-for="testimonial in testimonials" :key="testimonial.id"
                                    class="sm:flex lg:block">
                            <svg width="24" height="18" viewBox="0 0 24 18" aria-hidden="true"
                                 class="flex-shrink-0 text-gray-300">
                                <path
                                    d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                                    fill="currentColor"/>
                            </svg>
                            <div class="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                                <p class="text-lg text-gray-600">{{ testimonial.quote }}</p>
                                <cite class="mt-4 block font-semibold not-italic text-gray-900">{{
                                        testimonial.attribution
                                    }}</cite>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </section>

            <!-- CTA section -->
            <section aria-labelledby="sale-heading" v-if="sale[0]?.discount">
                <div class="overflow-hidden pt-32 sm:pt-14">
                    <div class="bg-gray-200">
                        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div class="relative pb-16 pt-48 sm:pb-24">
                                <div>
                                    <h2 id="sale-heading"
                                        class="text-4xl font-bold tracking-tight text-black md:text-5xl">
                                        Online Only Sale
                                        <br/>
                                        Up to {{ sale[0].discount.amount }}% off.
                                    </h2>
                                    <p class="mt-2 text-gray-600">We're always running sales on the most popular
                                        products, exclusively online.</p>
                                    <div class="mt-6 text-base">
                                        <Link :href="route('products.browse', {special: 'Sale'})"
                                              class="font-semibold text-black">
                                            Shop the sale
                                            <span aria-hidden="true"> &rarr;</span>
                                        </Link>
                                    </div>
                                </div>

                                <div
                                    class="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                                    <div class="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                                        <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                            <div class="flex-shrink-0">
                                                <Link :href="sale[0].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[0].imageSrc" alt=""/>
                                                </Link>
                                            </div>
                                            <div v-if="sale[1]" class="mt-6 flex-shrink-0 sm:mt-0">
                                                <Link :href="sale[1].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[1].imageSrc" alt=""/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div
                                            class="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                            <div v-if="sale[2]" class="flex-shrink-0">
                                                <Link :href="sale[2].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[2].imageSrc" alt=""/>
                                                </Link>
                                            </div>

                                            <div v-if="sale[3]" class="mt-6 flex-shrink-0 sm:mt-0">
                                                <Link :href="sale[3].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[3].imageSrc" alt=""/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                            <div v-if="sale[4]" class="flex-shrink-0">
                                                <Link :href="sale[4].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[4].imageSrc" alt=""/>
                                                </Link>
                                            </div>

                                            <div v-if="sale[5]" class="mt-6 flex-shrink-0 sm:mt-0">
                                                <Link :href="sale[5].href">
                                                    <img class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                                         :src="sale[5].imageSrc" alt=""/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </StoreLayout>
</template>
